import React, { useState } from "react"
import { Midi } from "@tonejs/midi"
import { connect } from "react-redux"
import Layout from "../../components/Layout"

const Upload = ({
  musicReadyCount,
  audioReadyCount,
  conductorMusic,
  conductorStart,
}) => {
  const [music, setMusic] = useState(false)
  const [musicPrepped, setMusicPrepped] = useState(false)
  function sendMusic() {
    conductorMusic(music)
    setMusicPrepped(true)
  }

  function startTheMusic() {
    conductorStart()
  }

  function stopTheMusic() {
    // window.socket.emit("conductor-stop")
  }

  const onChangeHandler = e => {
    const reader = new FileReader()
    reader.onload = async function (e) {
      const midi = await new Midi(e.target.result)
      const filteredMidiTracks = midi.tracks.filter(
        ({ notes, instrument }) => notes.length !== 0 && !instrument.percussion
      )
      midi.tracks = filteredMidiTracks
      midi.name = midi.name || "Unkown Track"

      setMusic(midi)
      console.log(midi)
    }
    reader.readAsArrayBuffer(e.target.files[0])
  }

  return (
    <Layout>
      <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 sm:col-span-1  bg-teal-200 p-5 rounded text-teal-800 text-center flex items-center justify-center">
        <i class="las la-volume-up text-2xl mr-2"></i>
        {audioReadyCount} audio ready
      </div>
      <div
        className={`col-span-2 sm:col-span-1 ${
          audioReadyCount === musicReadyCount
            ? "bg-teal-200 text-teal-800"
            : "bg-orange-200 text-teal-800"
        } p-5 rounded  text-center flex items-center justify-center`}
      >
        <i class="las la-music text-2xl mr-2"></i>
        {musicReadyCount} music ready
      </div>

      <label class="col-span-2 flex flex-col items-center px-4 py-6 bg-teal-100 text-teal-800 rounded tracking-wide uppercase cursor-pointer hover:bg-teal-200">
        <i class="las la-upload text-2xl mr-2"></i>
        <span class="mt-2 text-base leading-normal">Drop a Midi</span>
        <input
          id="file-upload"
          type="file"
          className="hidden"
          onChange={onChangeHandler}
        />
      </label>

      {music && (
        <>
          <div
            className={`col-span-2 sm:col-span-1 bg-teal-200 text-teal-800"
           p-5 rounded  text-center flex items-center justify-center`}
          >
            <i class="las la-music text-2xl mr-2"></i>
            {music.tracks.length} tracks in MIDI file
          </div>

          <button
            className="col-span-2 btn-secondary"
            onClick={() => sendMusic()}
          >
            Prep the music!
          </button>
          <button
            disabled={!musicPrepped}
            className={`col-span-2 btn ${
              !musicPrepped ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => startTheMusic()}
          >
            Start the music!
          </button>
          <button
            className={`col-span-2 btn bg-red-400 ${
              !musicPrepped ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => stopTheMusic()}
          >
            Stop the music!
          </button>
        </>
      )}
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ musicReadyCount, audioReadyCount }) => {
  return { musicReadyCount, audioReadyCount }
}

const mapDispatchToProps = dispatch => {
  return {
    conductorStart: () => dispatch({ type: "server/conductor-start" }),
    conductorMusic: e =>
      dispatch({ type: "server/conductor-music", data: { music: e } }),
  }
}

const ConnectedUpload =
  typeof window !== `undefined`
    ? connect(mapStateToProps, mapDispatchToProps)(Upload)
    : Upload

export default ConnectedUpload
